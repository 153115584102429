



























































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Howl, Howler } from 'howler';
import { Getter } from 'vuex-class';
import { AudioLibraryItem } from '@/interfaces/world/library';
import { Watch } from 'vue-property-decorator';
import { isNull } from 'util';
import FirebaseGateway from '@/classes/firebaseGateway';

@Component
export default class Audiothek extends Vue {
  private muted = false;
  private volume = 0.5;
  private seek: number = 0;
  /** Dialog stuff */
  private showCreateDialog = false;
  private uploading = false;
  private uploadError = null;
  private selectedFile: File | null = null;
  private fileLoading = false;
  private fileError: string | null = null;
  private playing = false;
  private updateSeek;
  private currentTrackIndex: number | null = null;
  private isSaving = false;
  private uploadProgress = 0;

  private newLibraryItem: AudioLibraryItem = {
    id: null,
    title: '',
    duration: null,
    url: null,
    speaker: '',
    description: null,
  };
  private currentHowl: Howl | null = null;

  @Getter
  private audioLibrary!: AudioLibraryItem[];

  @Getter
  private currentWorld!: string;

  get dialogReady() {
    if (this.selectedFile === null) {
      return false;
    }

    if (this.fileError !== null) {
      return false;
    }

    if (this.newLibraryItem.duration === null) {
      return false;
    }

    return true;
  }

  get currentTrack() {
    if (this.currentTrackIndex === null) {
      return null;
    }
    return this.audioLibrary[this.currentTrackIndex];
  }

  get trackProgress() {
    if (this.currentTrack === null) {
      return 0;
    }
    if (this.currentTrack.duration === null) {
      return 0;
    }
    return (this.seek / this.currentTrack.duration) * 100;
  }

  set trackProgress(newSeek: number) {
    if (this.currentHowl === null) {
      throw new Error('No track playing');
    }

    if (this.currentTrack === null) {
      throw new Error('No current track');
    }

    if (this.currentTrack.duration === null) {
      return;
    }

    const newPosition = Math.round(this.currentTrack.duration * (newSeek / 100));
    console.log('seek to ' + newPosition);
    this.currentHowl.seek(newPosition);
  }

  public mounted() {
    this.$store.dispatch('loadAudioLibrary');
  }

  @Watch('playing')
  private watchPlaying(playing: boolean) {
    if (playing) {
      if (this.currentHowl !== null) {
        this.seek = this.currentHowl.seek() as number;
      }
      this.updateSeek = setInterval(() => {
        if (this.currentHowl !== null) {
          this.seek = this.currentHowl.seek() as number;
        }
      }, 250);
    } else {
      console.log('clear interval');
      clearInterval(this.updateSeek);
    }
  }

  private toggleMute() {
    this.muted = !this.muted;
    Howler.mute(this.muted);
  }

  private updateVolume(volume: number) {
    this.volume = volume;
    Howler.volume(this.volume);
  }

  private previousTrack() {
    if (this.currentTrackIndex === null) {
      return;
    }
    if (this.currentTrackIndex > 0) {
      this.selectTrack(this.currentTrackIndex - 1);
    }
  }

  private nextTrack() {
    if (this.currentTrackIndex === null) {
      return;
    }
    if (this.currentTrackIndex < this.audioLibrary.length) {
      this.selectTrack(this.currentTrackIndex + 1);
    }
  }

  private pauseTrack() {
    if (this.currentHowl === null) {
      throw new Error('No tracking playing');
    }

    this.currentHowl.pause();
    this.playing = false;
  }

  private resumeTrack() {
    if (this.currentHowl === null) {
      throw new Error('No tracking playing');
    }

    this.currentHowl.play();
    this.playing = true;
  }

  private playTrack() {
    if (this.currentHowl != null) {
      this.currentHowl.stop();
      this.currentHowl = null;
    }

    if (this.currentTrack === null) {
      throw new Error('No current track');
    }

    if (this.currentTrack.url === null) {
      return;
    }

    this.currentHowl = new Howl({
      src: [this.currentTrack.url],
    });
    this.currentHowl.play();
    this.playing = true;
  }

  private stopTrack() {
    if (this.currentHowl === null) {
      throw new Error('No tracking playing');
    }

    this.currentHowl.stop();
    this.playing = false;
    this.currentTrackIndex = null;
    this.currentHowl = null;
  }

  private selectTrack(trackIndex: number) {
    // No nothing on clicking of same track
    if (this.currentTrackIndex === trackIndex) {
      return;
    }

    if (this.playing) {
      this.stopTrack();
    }

    this.currentTrackIndex = trackIndex;
    console.log('start playing');
    this.playTrack();
  }

  private toggleTrack() {
    if (this.playing) {
      this.pauseTrack();
    } else {
      this.resumeTrack();
    }
  }

  private uploadLibraryItem() {
    if (this.selectedFile === null) {
      throw new Error('selectedFile is null');
    }
    this.isSaving = true;
    const uploadPath = '/worlds/' + this.currentWorld + '/audio/';
    const progressFuntion = (progress: number) => {
      this.uploadProgress = progress;
    };
    new FirebaseGateway().uploadFile(this.selectedFile, uploadPath, false, progressFuntion).then((url) => {
      this.newLibraryItem.url = url;
      this.$store
        .dispatch('createAudioLibraryItem', this.newLibraryItem)
        .then((result) => {
          this.isSaving = false;
          this.resetDialog();
        })
        .catch((error) => {
          this.isSaving = false;
          throw new Error(error);
        });
    });
  }

  private resetDialog() {
    this.newLibraryItem = {
      id: null,
      title: '',
      url: null,
      duration: null,
      speaker: '',
      description: null,
    };
    this.showCreateDialog = false;
  }

  private checkFile(value: File | null) {
    this.fileError = null;
    this.newLibraryItem.duration = null;

    if (value === null) {
      return;
    }

    const reader = new FileReader();
    this.fileLoading = true;
    reader.addEventListener('load', () => {
      const data = reader.result;

      if (data === null) {
        throw new Error('Unable to read local file');
      }
      const extension = value.name.split('.').pop()?.toLowerCase();

      if (typeof extension === 'undefined') {
        throw new Error('Unable to read extension of ' + value.name);
      }

      const errorFunction = (soundId, error) => {
        this.fileLoading = false;
        this.fileError = error as string;
      };

      // Create a Howler sound
      const sound = new Howl({
        src: [data as string],
        // Error function if sound cannot be loaded
        onloaderror: errorFunction,
        onplayerror: errorFunction,
        format: [extension], // always give file extension: this is optional but helps
      });
      // When sound has loaded, check duration
      sound.on('load', () => {
        this.fileLoading = false;
        if (sound.duration() === 0) {
          this.fileError = 'No duration';
          throw new Error('Unable to determine duration of ' + value.name);
        }
        this.newLibraryItem.duration = Math.round(sound.duration());
      });
    });
    // Read the file
    reader.readAsDataURL(value);
  }
}
